import React, { Dispatch } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { CaptchaRequest } from './types';

export interface GuardedCaptchaV3Props<Result> {
  action: string;
  onCaptchaAllowed: Dispatch<Result>;
  onCaptchaBlocked: () => void;
  onRequestFailed: Dispatch<Error>;
  request: CaptchaRequest<Result>;
}

export function GuardedCaptchaV3<Result>({
  action,
  onCaptchaAllowed,
  onCaptchaBlocked,
  onRequestFailed,
  request,
}: GuardedCaptchaV3Props<Result>) {
  const onVerify = async (captchaToken: string) => {
    try {
      const response = await request(captchaToken);

      if (response.allowed) {
        onCaptchaAllowed(response.result);
      } else {
        if (response.error) {
          onRequestFailed(response.error);
        }
        onCaptchaBlocked();
      }
    } catch (error) {
      onRequestFailed(error);
    }
  };

  return <GoogleReCaptcha action={action} onVerify={onVerify} />;
}
