import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ccdata } from '../ccdata';

export interface GuardedCaptchaProviderProps extends React.PropsWithChildren {}

const HiddenBadge = styled.div(css({ visibility: 'hidden' }));

/**
 * Basic wrapper for GoogleReCaptchaProvider
 */
export function GuardedCaptchaProvider({
  children,
}: GuardedCaptchaProviderProps) {
  const id = 'googleBadge';

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={ccdata.get('recaptcha_key_v3')}
        scriptProps={{ async: true, appendTo: 'body' }}
      >
        {children}
      </GoogleReCaptchaProvider>
      <HiddenBadge id={id} />
    </>
  );
}
