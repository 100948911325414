import React, { useState } from 'react';

import { ccdata } from '../ccdata';
import { GuardedCaptchaV2 } from './GuardedCaptchaV2';
import { GuardedCaptchaV3 } from './GuardedCaptchaV3';
import { CaptchaGuardedRequest, CaptchaGuardedResult } from './types';

export type GuardedCaptchaProps<Result> = CaptchaGuardedRequest<Result> & {
  resolve: (result: CaptchaGuardedResult<Result>) => void;
};

type CaptchaState = 'sending-v3' | 'sending-v2' | 'complete';

/**
 * Manages sending a request that first calls to reCAPTCHA v3, then v2.
 * If a request succeeds or fails, we immediately resolve with that result.
 * 1. First try sending it with a reCAPTCHA v3 ("hidden") token
 * 2. If the v3 request was captcha-blocked, try again with a v2 ("visible") token
 */
export function GuardedCaptcha<Result>({
  action,
  version = 'v3',
  requestV2,
  requestV3,
  resolve,
}: GuardedCaptchaProps<Result>) {
  const [state, setState] = useState<CaptchaState>(
    `sending-${version}` as CaptchaState
  );

  const onRequestBlocked = () => {
    resolve({ status: 'blocked' });
    setState('complete');
  };

  const onRequestFailure = (error: Error) => {
    resolve({ error, status: 'failed' });
    setState('complete');
  };

  const onRequestSuccess = (result: Result) => {
    resolve({ result, status: 'complete' });
    setState('complete');
  };

  switch (state) {
    case 'complete':
      return null;

    case 'sending-v3':
      return (
        <GuardedCaptchaV3
          action={action}
          onCaptchaAllowed={onRequestSuccess}
          onCaptchaBlocked={() => setState('sending-v2')}
          onRequestFailed={onRequestFailure}
          request={requestV3}
        />
      );

    case 'sending-v2':
      return (
        <GuardedCaptchaV2
          onCaptchaAllowed={onRequestSuccess}
          onCaptchaBlocked={onRequestBlocked}
          onRequestFailed={onRequestFailure}
          reCaptchaKey={ccdata.get('recaptcha_key_v2')}
          request={requestV2}
        />
      );
  }
}
